import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth } from './../../auth'
import { Pane, Alert, Button, Heading, TextInput } from 'evergreen-ui'

export default function Auth() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [warning, setWarning] = useState('')

  const history = useHistory()
  const location = useLocation()
  const auth = useAuth()

  const { from } = location.state || { from: { pathname: '/' } }
  const login = async () => {
    try {
      setWarning('')
      await auth.signin(username, password, () => {
        history.replace(from)
      })
    } catch (err) {
      console.warn(err)
      setWarning('错误')
    }
  }

  return (
    <Pane display='flex' alignItems='center' flexDirection='column'>
      <Heading size={700} marginTop='default'>
        登录
      </Heading>
      <Pane display='flex' alignItems='center' flexDirection='column' marginTop={24} className='form-fields'>
        <TextInput placeholder='用户名' onChange={(e) => setUsername(e.target.value)} />
        <TextInput marginTop={8} type='password' placeholder='密码' onChange={(e) => setPassword(e.target.value)} />
        <Button marginTop={16} appearance='primary' onClick={login}>
          登录
        </Button>
      </Pane>
      <Pane marginTop={16} width='70%'>
        {warning && <Alert intent='danger' title={warning} />}
      </Pane>
    </Pane>
  )
}
