import { useState, createContext, useContext } from 'react'
import API, { AuthAPI } from './api'

const token = localStorage.getItem('session_token') || null
if (token) {
  API.defaults.headers.common['X-Parse-Session-Token'] = token
}

const storedUser = localStorage.getItem('user_data') || null
let userData = null
try {
  userData = JSON.parse(storedUser)
} catch (err) {
  localStorage.removeItem('user_data')
  localStorage.removeItem('session_token')
}

const authHub = {
  isAuthenticated: !!token,
  async signin(u, p, cb) {
    try {
      const res = await AuthAPI.signin(u, p)
      authHub.isAuthenticated = true
      // set Parse server session token
      API.defaults.headers.common['X-Parse-Session-Token'] = res.sessionToken
      localStorage.setItem('session_token', res.sessionToken)
      localStorage.setItem('user_data', JSON.stringify(res))
      cb(res)
    } catch (err) {
      throw err
    }
  },
}

const authContext = createContext()

export function useAuth() {
  return useContext(authContext)
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

function useProvideAuth() {
  const [user, setUser] = useState(userData || null)

  const signin = async (u, p, cb) => {
    return authHub.signin(u, p, (res) => {
      console.log(res)
      setUser(res)
      cb()
    })
  }

  return {
    user,
    signin,
  }
}
