import React from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { useAuth, ProvideAuth } from './auth'
import AuthPage from './pages/auth'
import SearchPage from './pages/search'

const Routes = () => {
  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <Route path='/login'>
            <AuthPage />
          </Route>
          <PrivateRoute path='/'>
            <SearchPage />
          </PrivateRoute>
        </Switch>
      </Router>
    </ProvideAuth>
  )
}

const PrivateRoute = ({ children, ...rest }) => {
  const auth = useAuth()
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? children : <Redirect to={{ pathname: '/login', state: { from: location } }} />
      }
    />
  )
}

export default Routes
