import React, { useState,useEffect } from 'react'
//import { useAuth } from './../../auth'
import { MedicalAPI } from './../../api'
import { Pane, Heading, GlobeIcon, Text, Paragraph, Alert, Badge, SearchInput, Button, Avatar } from 'evergreen-ui'

export default function Search() {
  const [input, setInput] = useState('')
  const [medicalList, setMedicalList] = useState(null)
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [loadingMedId, setLoadingMedId] = useState('')

  //  const auth = useAuth()
  useEffect(async () => {
    // Update the document title using the browser API
    try {
      setLoading(true)
      setErrorMsg('')
      const res = await MedicalAPI.searchdef()
      setMedicalList(res['results'] || [])
    } catch (err) {
      setErrorMsg('错误：' + err.toString())
    } finally {
      setLoading(false)
    }
  },[]);

  const search = async () => {
    try {
      if (!input) {
        return
      }

      setLoading(true)
      setErrorMsg('')
      const res = await MedicalAPI.search(input)
      setMedicalList(res['results'] || [])
    } catch (err) {
      setErrorMsg('错误：' + err.toString())
    } finally {
      setLoading(false)
    }
  }

  const getBadgeText = (status) => {
    switch (status) {
      case 'pending':
        return { color: 'yellow', text: '待开通' }
      case 'active':
        return { color: 'green', text: '已开通' }
      case 'suspended':
        return { color: 'red', text: '已停权' }
      default:
        return { color: 'neutral', text: status }
    }
  }

  const toggleStatus = async (medId, newStatus) => {
    try {
      setLoading(true)
      setLoadingMedId(medId)
      await MedicalAPI.setStatus(medId, newStatus)
      await search()
    } catch (err) {
      console.warn(err)
      alert(`操作失败：${err.data ? err.data.error : err.toString()}`)
    } finally {
      setLoading(false)
      setLoadingMedId('')
    }
  }

  return (
    <Pane padding={12} maxWidth={500} marginLeft='auto' marginRight='auto' background='tint1' minHeight='100vh'>
      <Heading size={700}>医务人员账号管理</Heading>
      <Paragraph marginTop={12}>请输入手机号并搜索医生数据，可以输入完整或部分手机号。</Paragraph>
      <Pane marginTop={18} display='flex'>
        <SearchInput
          type='tel'
          height={48}
          width='100%'
          onChange={(e) => setInput(e.target.value)}
          disabled={loading}
        />
        <Button height={48} marginLeft={6} width={96} appearance='primary' onClick={search} disabled={loading}>
          搜索
        </Button>
      </Pane>
      <Pane marginTop={16} width='70%'>
        {errorMsg && <Alert intent='danger' title={errorMsg} />}
      </Pane>

      {Array.isArray(medicalList) && (
        <Pane marginTop={36}>
          <Heading size={500}>搜索结果（{medicalList.length}）</Heading>
          <Pane>
            {medicalList.map((medical, i) => (
              <Pane
                key={medical.objectId + i}
                display='flex'
                flexDirection='column'
                marginTop={12}
                elevation={1}
                borderRadius={5}
                padding={12}
                background='white'
              >
                <Pane display='flex'>
                  <Pane>
                    <Avatar src={medical.user.avatarUrl} name={medical.realName} size={64} />
                  </Pane>
                  <Pane width='100%'>
                    <Pane display='flex' justifyContent='space-between' width='100%' paddingLeft={16}>
                      <Pane display='flex' flexDirection='column'>
                        <Heading size={500}>{medical.realName}</Heading>
                        <Text color='muted'>{medical.user.phoneNumber}</Text>
                      </Pane>
                      <Pane display='flex' alignItems='flex-start'>
                        <Badge marginLeft={12} color={getBadgeText(medical.status).color}>
                          {getBadgeText(medical.status).text}
                        </Badge>
                      </Pane>
                    </Pane>
                    <Pane display='flex' paddingLeft={16} marginTop={8}>
                      <GlobeIcon color='muted' />
                      <Text marginLeft={8}>{medical.user.province || ''}</Text>
                      <Text marginLeft={8}>{medical.user.city || ''}</Text>
                      <Text marginLeft={8}>{medical.user.createdAt || ''}</Text>
                    </Pane>
                    <Pane paddingLeft={16} marginTop={8}>
                      <Paragraph color={medical.bio ? 'default' : 'muted'}>{medical.bio || '未填写简介'}</Paragraph>
                    </Pane>
                  </Pane>
                </Pane>
                <Pane marginTop={12} display='flex' justifyContent='flex-end'>
                  <Button
                    disabled={medical.status === 'suspended'}
                    isLoading={loadingMedId === medical.objectId}
                    intent='danger'
                    onClick={() => toggleStatus(medical.objectId, 'suspended')}
                  >
                    停权
                  </Button>
                  <Button
                    disabled={medical.status === 'active'}
                    isLoading={loadingMedId === medical.objectId}
                    intent='success'
                    onClick={() => toggleStatus(medical.objectId, 'active')}
                    marginLeft={8}
                  >
                    开通
                  </Button>
                </Pane>
              </Pane>
            ))}
          </Pane>
        </Pane>
      )}
    </Pane>
  )
}
