import API from '..'

export const MedicalAPI = {
  search: (phone) => {
    return API.get(`/classes/MedicalStaffFile`, {
      params: {
        include: 'user',
        where: JSON.stringify({
          user: {
            $inQuery: {
              where: { phoneNumber: { $regex: phone } },
              className: '_User',
            },
          },
        }),
      },
    })
  },
  searchdef: () => {
    return API.get(`/classes/MedicalStaffFile`, {
      params: {
        include: 'user,medicalStaffUser',
        order: '-createdAt',
        limit: 50,
        where: JSON.stringify({ status: "pending" }),
      },
    })
  },
  setStatus: (medId, newStatus) => {
    return API.post(`/functions/sa_set_medical_user_status`, {
      medId,
      newStatus,
    })
  },
}
