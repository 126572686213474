import API from '..'

export const AuthAPI = {
  signin: (u, p) => {
    return API.post('/login', {
      username: u,
      password: p,
    })
  },
}
