import axios from 'axios'

const API = axios.create({
  baseURL: process.env.REACT_APP_API_HOST || 'https://api.mini.qsjpf.com/v1/rest',
  timeout: 10 * 1000,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    'X-Parse-Application-Id': 'jpf_server',
  },
})

API.interceptors.response.use(
  (resp) => resp.data,
  (err) => Promise.reject(err.response || `服务器无法处理此次请求`),
)

export default API

export { AuthAPI } from './endpoints/auth'
export { MedicalAPI } from './endpoints/medical'
